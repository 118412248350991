import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Photomask lithography`}</h4>
    <p>{`In photomask lithography, lithographic processes are used to produce photomasks, which are then used in chip photolithography to create advanced logic chips. Photomasks are produced in lower volume than chips (a small number of photomasks are used to produce a large number of chips), making it feasible to use maskless lithographic techniques that have lower throughput than photolithography. The specific techniques used to produce photomasks include photolithography, electron-beam lithography, and laser lithography. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      